body {
  overscroll-behavior-y: contain; // prevents pull refresh breaks soft routes
  display: block;

  * {
    box-sizing: border-box;
  }

  .css-delayed-fade-in {
    animation: fadeIn 2s 1;
  }

  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  #root {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;

    // this is for Desktop view since the app is designed for portrait/phones
    @media screen and (min-width: 1024px) {
      position: absolute;
      top: 0;
      left: 50%;
      width: 1024px;
      height: 100%;
      max-width: 640px;
      transform: translateX(-50%);
      border-left: 1px solid #E1E1E3;
      border-right: 1px solid #E1E1E3;
    }
  }
}