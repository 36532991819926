@import './../../assets/styles/flex-rules.scss';

.tagging-tracker__addresses {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

.tagging-tracker__address-wrapper {
    @include flex-hor-row-ali-str;
    flex-direction: row;
    width: 100%;
    padding-left: 1rem;
    border-bottom: 1px solid #F3F3F5;
}

.tagging-tracker__address-pdf-icon {
    @include flex-center;
    flex: 0;
    border: 1px solid #383838;
    margin: 0.25rem;

    span {
        font-size: 0.5rem;
        background-color: red;
        color: white;
        width: 100%;
        padding: 0.125rem 0.25rem;
        font-weight: bold;
    }

    &.in-progress {
        opacity: 0.6;
        pointer-events: none;
    }
}

.tagging-tracker__address {
    @include flex-hor-row-ali-str;
    justify-content: flex-start;
    flex: 1;
    padding: 0.5rem 1rem;
    padding-left: 0.5rem;
    text-decoration: none;
    color: #282828;

    h4 {
        @include flex-left;
        font-size: 1rem;
        width: 100%;
    }

    img {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.tagging-tracker__address-input-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -50%);
    background-color: #F9F9F9;
    padding: 1rem 0;
    padding-bottom: 0;
    border-radius: 6px;
    @include flex-ver-col;
    font-family: 'Roboto Regular', sans-serif;
    min-width: 250px;
    z-index: 2;

    h3 {
        font-weight: bold;
    }

    p {
        margin-top: 0.5rem;
        text-align: center;
        font-size: 0.875rem;
        padding: 0 1rem;

        &.disclaimer-text {
            font-size: 0.65rem;
            color: red;
        }
    }

    input {
        border-top: 1px solid #CECDD0;
        flex: 1;
    }
}

.tagging-tracker__address-input-row {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 1rem;
}

.address-input-row___location-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
    flex: 0;
}

.tagging-tracker__address-input-modal-btns {
    @include flex-hor-row-ali-str;
    margin-top: 0.5rem;
    width: 100%;
    border-top: 1px solid #CECDD0;

    button {
        color: #1B89FD;
        background-color: transparent;
        border: 0;
        width: 50%;
        padding: 1rem;

        &:last-of-type {
            border-left: 1px solid #CECDD0;
        }

        &:disabled {
            opacity: 0.6;
        }
    }
}

.tagging-tracker__reverse-geocoded-addresses {
    max-height: 200px;
    overflow: hidden auto;
}

.tagging-tracker__reverse-geocoded-address {
    padding: 0.5rem;

    &:not(:first-of-type) {
        border-top: 1px solid #CECDD0;
    }
}

.tagging-tracker__reverse-geocode-back-btn {
    transform: translate(-50%);
    margin: 0.5rem 0;
    margin-left: 50%;
    color: #1B89FD;
    background-color: white;
    border: 1px solid #282828;
    border-radius: 2px;
    padding: 0.25rem 0.5rem;
}