@import './../../assets/styles/flex-rules.scss';

.tagging-tracker__page-404 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex-center;
    flex-direction: column;

    h2 {
        font-family: 'Roboto Bold', sans-serif;
        font-weight: bold;
        font-size: 1.25rem;
    }

    p {
        margin-top: 0.5rem;
    }

    a {
        margin-top: 0.5rem;
        
    }
}