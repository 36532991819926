@import './../../assets/styles/flex-rules.scss';

.tagging-tracker__view-address {
    @include flex-left-start;
    flex-wrap: wrap;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    
    .address__tag-image {
        width: 300px;
        height: 300px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border: 1px solid #aaa;
        margin: 0.5rem;

        @media screen and (max-width: 500px) {
            width: 150px;
            height: 150px;
            background-size: cover;
        }
    }
}