@import './../../assets/styles/flex-rules.scss';

.tagging-tracker__view-address {
    @include flex-left-start;
    flex-wrap: wrap;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    
    .address__tag-image {
        width: 300px;
        height: 300px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border: 1px solid #aaa;
        margin: 0.5rem;

        @media screen and (max-width: 500px) {
            width: 150px;
            height: 150px;
            background-size: cover;
        }
    }
}

.tagging-tracker__view-address-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include flex-center;

    span {
        font-size: 1.125rem;
        margin-right: 0.25rem;
    }
}

.tagging-tracker__view-address-tag-date-group {
    width: 100%;
    padding: 0.5rem;

    &:not(:first-of-type) {
        margin-top: 0.5rem;
    }

    span {
        font-weight: bold;
    }
}

.tagging-tracker__view-address-no-tags {
    @include flex-center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}